import React from 'react'
import Layout from '../components/Layout'
const NotFoundPage = () => (
  
    <Layout>
        <div>
            <h1>Az oldal nem található </h1>
            
        </div>
    </Layout>
)


export default NotFoundPage